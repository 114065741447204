.tray {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  height: 30px;
  position: absolute;
  bottom: var(--navigation-clearance);
  width: 100%;
  transition: height 0.3s cubic-bezier(0.76, 0, 0.24, 1);
  border-radius: var(--16px) var(--16px) 0 0;
  background-color: #393939;
  background-color: rgba(22, 22, 23, 0.8);
  backdrop-filter: saturate(1.8) blur(20px);
  padding-top: calc((30px - 4px) / 2);
  --gap: 42px;
}
.tray.expanded {
  height: calc(
    var(--height) - var(--navigation-clearance) - var(--header-clearance) -
      var(--gap)
  );
}
.tray .toggle {
  width: 34px;
  border-radius: 4px;
  height: 4px;
  flex-shrink: 0;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.5);
  margin-bottom: calc((30px - 4px) / 2);
}
