.header {
  background-color: #c2c2c2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 24px;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-end;
  gap: 8px;
  color: #000;
}
.row {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 8px;
}
.gems {
  background-color: #333333;
  padding: 3px 14px;
  color: #fff;
  border-radius: 25px;
}
.avatar {
  height: 27px;
  width: 27px;
  display: block;
  flex-shrink: 0;
  background-color: #71fff6;
  border-radius: 100%;
}
.scroller {
  width: calc(100% - 10px * 2);
  margin: 12px 10px;
  /*margin-top: calc(130px + 12px);*/
  margin-top: 0;
  display: flex;
  flex-flow: column nowrap;
  gap: 12px;
  overflow-y: auto;
  /*max-height: calc(100% - 130px - 12px);*/
  /*padding-bottom: calc(var(--navigation-clearance) + 12px);*/
  min-height: 0;
  position: relative;
}
.building,
.add {
  height: 110px;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 10px;
  padding-left: 13px;
  position: relative;
  box-shadow:
    inset 0 0 0 1px var(--accent, #c2c2c2),
    inset 4px 0 0 0 var(--accent, #c2c2c2);
  gap: 10px;
  flex-shrink: 0;
}
.select {
  height: 100%;
  position: relative;
  width: 100%;
  gap: 6px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.choice {
  height: 100%;
  box-shadow: inset 0 0 0 1px #c2c2c2;
  cursor: pointer;
}
.add {
  cursor: pointer;
}
.image {
  flex-shrink: 0;
  height: 100%;
  background-color: var(--accent, #4343de);
  aspect-ratio: 1/1;
  width: auto;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 6px;
}
.add .image {
  background-color: #c2c2c2;
}
.body {
  flex-grow: 1;
  min-width: 0;
  flex-shrink: 1;
  height: 100%;
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
}
.info {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: 100%;
  flex-shrink: 0;
}
.left {
  display: flex;
  flex-flow: column nowrap;
}
.district {
  color: var(--accent);
  font-size: 9px;
}
.name {
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
}
.reputation {
  font-size: 11px;
  color: #d4d9d9;
}
.upgrade {
  padding: 0 8px;
  color: rgba(10, 10, 10, 0.8);
  cursor: pointer;
  display: flex;
  flex-flow: column nowrap;
  min-width: 74px;
  align-items: flex-end;
  justify-content: center;
  font-size: 17px;
  gap: 1px;
  height: 42px;
  /*clip-path: polygon(84% 0, 100% 22%, 100% 100%, 0 100%, 0 0);*/
  mask-image: url(./upgrade.svg);
  mask-size: cover;
  border-radius: 2px 0 0 2px;
  mask-position: top right;
  mask-repeat: no-repeat;
  background: radial-gradient(
      45% 100% at 85% 25%,
      rgba(242, 235, 86, 0.32) 0%,
      rgba(242, 235, 86, 0.1024) 100%
    ),
    #f8684f;
}
.upgrade:disabled {
  background: radial-gradient(
      45% 100% at 85% 25%,
      rgba(98, 223, 216, 0.08) 0%,
      rgba(98, 223, 216, 0) 100%
    ),
    rgba(87, 95, 126, 0.64);
  cursor: not-allowed;
  color: rgba(10, 10, 10, 0.64);
}
.upgrade span {
  font-size: 8px;
  font-weight: 600;
  color: rgba(10, 10, 10, 0.64);
}
.upgrade:disabled span {
  color: rgba(10, 10, 10, 0.48);
}
.earnings {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #f2eb56;
  flex-grow: 1;
  margin-top: 2px;
  color: #0a0a0a;
  font-size: 16px;
  font-weight: 600;
  position: relative;
  cursor: pointer;
  /*clip-path: polygon(100% 0, 100% 58%, 97% 100%, 0 100%, 0 0);*/
  mask-image: url(./shape.svg);
  mask-size: cover;
  mask-position: bottom right;
  border-radius: 2px 0 0 2px;
  mask-repeat: no-repeat;
  overflow: hidden;
  transition: all 0.2s ease-in;
}
.earnings:disabled,
.earnings.disabled {
  background-color: rgba(87, 95, 126, 0.64);
  /*background-image: linear-gradient(
    to right,
    rgba(98, 223, 216, 0),
    rgba(98, 223, 216, 0.08)
  );*/
  color: rgba(10, 10, 10, 0.64);
}
.earnings:disabled {
  cursor: not-allowed;
}
.earnings.auto {
  background: transparent;
  background-image: linear-gradient(to right, #eceb5c, #62dfd8);
  cursor: progress;
}
.earnings.auto .texture {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 200%;
  min-width: 0;
  flex-shrink: 0;
  pointer-events: none;
  z-index: 0;
  display: block;
  background-image: url(../../assets/textures/progressFill.png);
  background-size: contain;
  background-position: left center;
  background-repeat: repeat-x;
  animation: progress 13s linear infinite;
}
.capacity {
  position: absolute;
  left: 3px;
  height: calc(100% - 6px);
  width: 4px;
  border-radius: 4px;
  background-color: rgba(10, 10, 10, 1);
  pointer-events: none;
  z-index: 10;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-end;
  overflow: hidden;
}
.capacity > span {
  width: 100%;
  transition: all 33ms ease-in;
  background-color: rgba(248, 104, 79, 1);
  display: block;
}
@keyframes progress {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 100% 0;
  }
}
.earnings span {
  z-index: 10;
}
.earnings .fill {
  position: absolute;
  left: 0;
  z-index: 0;
  height: 100%;
  background-color: #62dfd8;
  background-image: linear-gradient(
    to right,
    rgba(236, 235, 92, 0.56),
    rgba(98, 223, 216, 0.56)
  );
  background-size: 317px 100%;
  background-position: left center;
  transition: opacity 0.2s ease-in;
  opacity: 0;
}
.time {
  right: 6px;
  top: 5px;
  position: absolute;
  font-size: 8px;
  font-weight: 600;
}
