.root {
  --offset: var(--16px);
  width: calc(100% - var(--offset) * 2);
  position: absolute;
  top: var(--offset);
  left: var(--offset);
  z-index: 1000;
  box-shadow:
    inset 0 0 0 var(--2px) #626262,
    0px var(--8px) var(--16px) 0px #00000052;
  background-color: #d9d9d9;
  background-image: linear-gradient(
    to top right,
    rgba(10, 10, 10, 0.16),
    rgba(10, 10, 10, 0)
  );
  color: #0a0a0a;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  border-radius: var(--16px);
  padding: var(--24px);
}
.left,
.right {
  display: flex;
  flex-flow: column nowrap;
}
.left {
  align-items: flex-start;
}
.right {
  align-items: flex-end;
}
.airdrop {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: var(--24px);
  font-weight: 700;
  font-size: var(--40px);
  line-height: var(--48px);
  opacity: 0.32;
}
.airdrop > svg {
  height: var(--44px);
  width: fit-content;
}
.passive {
  align-items: center;
  display: flex;
  height: var(--52px);
  background-color: rgba(10, 10, 10, 0.8);
  padding: var(--6px);
  padding-right: var(--20px);
  border-radius: var(--26px);
  flex-flow: row nowrap;
  gap: var(--12px);
  font-weight: 700;
  color: rgba(217, 217, 217, 1);
  font-size: var(--40px);
  line-height: var(--48px);
  margin-bottom: var(--16px);
}
.passive > picture {
  height: var(--40px);
  width: var(--40px);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: rgba(217, 217, 217, 1);
  flex-shrink: 0;
}
.passive > picture > svg {
  color: rgba(10, 10, 10, 1);
  height: var(--28px);
}
.divider {
  width: var(--4px);
  height: var(--32px);
  border-radius: var(--3px);
  flex-shrink: 0;
  opacity: 0.48;
  background-color: rgba(217, 217, 217, 1);
}
.passive > em {
  font-size: var(--32px);
  line-height: var(--38px);
}
.money {
  font-weight: 700;
  font-size: var(--96px);
  line-height: var(--115px);
}
.money > em {
  font-weight: 400;
  font-size: var(--56px);
  line-height: var(--67px);
}
.user {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-end;
  margin-top: var(--16px);
  gap: var(--8px);
  text-transform: uppercase;
  font-weight: 700;
  font-size: var(--20px);
  line-height: var(--24px);
  letter-spacing: 0.5em;
}
.user > span {
  font-weight: 400;
}
