.root {
  height: var(--navigation-clearance);
  padding-bottom: var(--navigation-padding);
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 100;
  background-color: #393939;
  background-color: rgba(22, 22, 23, 0.8);
  backdrop-filter: saturate(1.8) blur(20px);
  color: #bcbcbc;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-evenly;
}
.link {
  text-transform: uppercase;
  cursor: pointer;
}
.link.active {
  color: #fff;
}
