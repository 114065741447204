body {
  margin: 0;
  font-family:
    Barlow,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  background-color: #000;
  height: 100vh;
  position: relative;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;

  --navigation-height: 60px;
  --navigation-padding: 0px;
  --navigation-clearance: calc(
    var(--navigation-height) + var(--navigation-padding)
  );
  touch-action: manipulation;
}
html,
body {
  overflow: hidden;
}
#root {
  /*width: 100%;
  height: 100%;
  max-width: 460px;
  max-height: 932px;*/
  width: var(--width);
  height: var(--height);
  max-height: var(--height);
  max-width: var(--width);
  background-color: #282828;
  background-image: url(./assets/textures/background.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  color: #a0a0a0;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
*:focus,
*:active {
  outline: none;
}
* {
  font: inherit;
  color: inherit;
  text-decoration: none;
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
  list-style: none;
  box-shadow: none;
  cursor: inherit;
  text-shadow: none;
}
html.loading #root {
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}
.loader {
  position: relative;
  width: var(--size, 100px);
  height: var(--size, 100px);
  transform-origin: center;
  transform: scale(var(--scale, 1));
}
.loader.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(var(--scale, 1));
}
.loader:before,
.loader:after {
  content: "";
  border-radius: 50%;
  position: absolute;
  inset: 0;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.3) inset;
}
.loader:after {
  box-shadow: 0 2px 0 #f8684f inset;
  animation: jfvvql1k7h-rotate var(--speed, 2s) linear infinite;
}
@keyframes jfvvql1k7h-rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

button,
button * {
  user-select: none;
}
:root {
  touch-action: pan-x pan-y;
  height: 100%;
  --scale-factor: 0.5;
  --header-height: calc(var(--100px) + var(--100px) + var(--103px));
  --header-clearance: calc(var(--header-height) + var(--16px) * 2);
}
