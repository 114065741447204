.root {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  user-select: none;
}
.container {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 100%;
  overflow: hidden;
}
.canvas {
  cursor: grab;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  flex-shrink: 0;
  will-change: transform;
  position: relative;
}
.canvas * {
  pointer-events: none;
}
.canvas .outline,
.canvas .fill {
  height: 100%;
  width: 100%;
  position: absolute;
  flex-shrink: 0;
  min-height: 0;
  min-width: 0;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.canvas .outline {
  z-index: 2;
  background-image: url(../../assets/textures/mapOutline.png);
}
.canvas .fill {
  z-index: 1;
  background-image: url(../../assets/textures/mapFill.png);
}
.slider {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  cursor: pointer;
  flex-grow: 1;
}
.slider:focus {
  outline: none;
}
.slider::-webkit-slider-runnable-track {
  background-color: #343434;
  border-radius: 8px;
  height: 8px;
}
.slider::-moz-range-track {
  background-color: #343434;
  border-radius: 8px;
  height: 8px;
}
.slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  margin-top: -11px; /* Centers thumb on the track */

  /*custom styles*/
  background-color: #ededed;
  height: 2rem;
  width: 14px;
  border-radius: 14px;
}
.slider::-moz-range-thumb {
  border: none; /*Removes extra border that FF applies*/
  /*border-radius: 0;*/ /*Removes default border-radius that FF applies*/

  /*custom styles*/
  background-color: #5cd5eb;
  height: 2rem;
  width: 14px;
  border-radius: 14px;
}
.slider:active::-webkit-slider-thumb {
  /*border: 1px solid #053a5f;*/
  /*outline: 2.5px solid #053a5f;*/
  outline: 2.5px solid #3577fd;
  outline-offset: 0.1rem;
}
.slider:active::-moz-range-thumb {
  outline: 2.5px solid #3577fd;
  outline-offset: 0.1rem;
}
.sliderWrapper {
  width: calc(100% - 20px);
  background-color: rgba(233, 233, 233, 0.8);
  border-radius: 23px;
  backdrop-filter: saturate(1.8) blur(20px);
  padding: 0 12px;
  position: absolute;
  bottom: 200px;
  z-index: 1000;
  display: none;
  flex-flow: row nowrap;
  align-items: center;
  gap: 12px;
  margin: 10px 0;
  height: 48px;
  margin-bottom: 14px;
}
.sliderWrapper > span {
  color: #3b3b3b;
  height: 20px;
  width: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  transform: translateY(-1px);
}
.gradient {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  pointer-events: none;
  background-image: url(../../assets/textures/mapGradient.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
