.root {
  height: 100%;
  width: 100%;
  padding: 20px;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
}
.root label {
  color: #eee;
  margin-bottom: 4px;
}
.root label:not(:first-child) {
  margin-top: 12px;
}
.root input {
  width: 100%;
  padding: 12px 12px;
  background-color: rgba(255, 255, 255, 0.4);
  color: #232323;
  flex-shrink: 0;
}
.root input:disabled {
  opacity: 0.5;
}
.spacer {
  flex-grow: 1;
}
.submit {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.8);
  color: #000;
  cursor: pointer;
}
.submit:not(:last-of-type) {
  margin-bottom: 18px;
}
.submit:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.error {
  color: #ff2a2a;
  margin-top: 4px;
}