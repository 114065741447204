.root {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  position: relative;
  width: 100%;
  margin-top: 3px;
  height: 16px;
  gap: 6px;
  flex-shrink: 0;
}
.label {
  color: #eee;
  font-size: 14px;
  font-weight: 600;
  flex-shrink: 0;
  line-height: 14px;
}
.bar {
  position: relative;
  flex-grow: 1;
  min-width: 0;
  flex-shrink: 1;
  display: grid;
  gap: 3px;
  align-items: center;
}
.debug {
  position: absolute;
  top: -40px;
  left: -90px;
  z-index: 100;
  pointer-events: none;
  font-size: 10px;
  color: #fff;
}
.solid {
  height: 7px;
  background-color: #fe4343;
  background-image: linear-gradient(
    to right,
    rgba(248, 104, 79, 1),
    rgba(98, 223, 216, 1)
  );
  background-size: 317px 100%;
  background-position: left center;
  background-repeat: no-repeat;
  border-radius: 2px 0 0 2px;
}
.solid:last-child {
  border-radius: 2px;
}
.half {
  height: 7px;
  background-color: #fe4343;
  opacity: 0.5;
}
.empty {
  height: 2px;
  display: flex;
  background-color: #5c5c5c;
}
